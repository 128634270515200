import React from 'react'
import { graphql, Link } from 'gatsby'
// import TrackVisibility from 'react-on-screen'
import Layout from '../components/Layout'
import MainGrid from '../components/styling/MainGrid'
import Breadcrumb from '../components/shared/Breadcrumb/Breadcrumb'

import UseCaseTemplateLayout from '../components/UseCases/UseCaseTemplateLayout'
import UseCaseLeftCol from '../components/UseCases/UseCaseLeftColumn'
import UseCaseRightCol from '../components/UseCases/UseCaseRightColumn'
import { LazyBlocksFactory } from '../components/LazyBlocksFactory/LazyBlocksFactory'
import TrackVisibility from 'react-on-screen'
import PromotionBannerAnimation from '../components/PromotionBannerAnimation'
import { RichTextContainer } from '../components/RichTextContainer/RichTextContainer'
import Button from '../components/shared/Button/Button'
import { appendUrlPartnerTracking } from '../util/url'
import arr from '../images/link-arrow.svg'
import styled from 'styled-components'

interface Props {
  data: any
  pageContext: any
}
const UseCasesTemplate = ({ data, pageContext }: Props) => {
  const { wpUseCase } = data
  const breadcrumbData = [
    {
      title: 'Home',
      url: '/',
    },
    {
      title: 'Use Cases',
      url: '/use-cases',
    },
    {
      title: wpUseCase.title,
      url: '/' + wpUseCase.slug,
    },
  ]
  // const { acfCourse2: courseData2 } = courseData

  const renderedBlocks = LazyBlocksFactory(wpUseCase.lazy_data, wpUseCase.title)

  return (
    <Layout>
      <MainGrid noPaddingMobile noPaddingTablet backgroundMobile="white">
        <Breadcrumb data={breadcrumbData} />
      </MainGrid>
      <UseCaseTemplateLayout>
        <UseCaseLeftCol title={wpUseCase.title} />
        <UseCaseRightCol>
          <h1 style={{ marginBottom: 67 }}>{wpUseCase.title}</h1>
          {renderedBlocks.map((v) => v)}
          <ButtonContainer>
            <Button width={'280px'} href={appendUrlPartnerTracking('/courses')}>
              View all courses
            </Button>
          </ButtonContainer>
          <Link to="/use-cases">
            {' '}
            <Arrow src={arr} alt="Arrow link" /> <b>Back to Use cases</b>
          </Link>
        </UseCaseRightCol>
      </UseCaseTemplateLayout>
    </Layout>
  )
}

export default UseCasesTemplate

export const pageQuery = graphql`
  query CaseById($id: String!) {
    wpUseCase(id: { eq: $id }) {
      title
      slug
      lazy_data
      use_cases_fields {
        description
      }
    }
  }
`
const Arrow = styled.img`
  width: 48px;
  height: 15px;
  transform: rotate(180deg);
  margin-right: 10px;
  margin-top: 15px;
`
const ButtonContainer = styled.div`
  margin: 80px 0;
`
